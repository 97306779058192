require("./src/styles/global.css");

const canonicalUrl = (slug, lang) => {
    // If page in en-US returns only the slug otherwise /$localLang/slug except where the slug for index should not be set
    let localSlug = '/'
    let languageSlug = ''

    if (slug != 'index' && slug != '' && slug) {
      localSlug = `/${slug}/`
    }
    if (lang === 'en-us' && slug === 'index'){
      localSlug = ''
    }
    if (lang === 'en-us') {
        languageSlug = ''
    } else {
      languageSlug = `/${lang.toLowerCase()}`
    }
    return (
      `${languageSlug}${localSlug}`
    )
  }

exports.onInitialClientRender = () => {
    const supportedLanguages = ['da-dk', 'de-de', 'en-gb', 'en-us', 'es-es', 'fr-fr', 'it-it', 'ja-jp', 'nl-be', 'pl-pl', 'pt-br', 'pt-pt', 'ru-ru', 'sv-sv', 'zh-cn', 'zh-tw'];
    const defaultLanguage = 'en-us';
    const cookieName = 'preferred_language';
  
    const getCookie = (name) => {
      const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
      return match ? match[2] : null;
    };
  
    const setCookie = (name, value, days = 365) => {
      const expires = new Date(Date.now() + days * 864e5).toUTCString();
      document.cookie = `${name}=${value}; path=/; expires=${expires}`;
    };
  
    const userSelectedLang = getCookie(cookieName);
  
    console.log(`User Language is ${userSelectedLang}`)
    // Already manually selected, do not redirect
    if (userSelectedLang) return;
  
    // Get the browser language, if exists in supportedLanguages = matchedLang if not defaults to defaultLanguage
    const browserLang = navigator.language.toLowerCase();
    const matchedLang = supportedLanguages.includes(browserLang) ? browserLang : defaultLanguage;
  
    // Get the current path
    const currentPath = window.location.pathname;
    const currentLang = supportedLanguages.includes(currentPath.split('/')[1]) ? currentPath.split('/')[1] : defaultLanguage;
    
    // If not already on the correct language path
    if (currentLang !== matchedLang) {
      setCookie(cookieName, matchedLang); // Save it so we don't redirect again
      window.location.pathname = canonicalUrl(window.location.pathname.split('/').at(-2), matchedLang);
    }
  };